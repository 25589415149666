import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { title } from "@core/utils/filter";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useUsersList() {
  // Use toast
  const toast = useToast();

  const refUserListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "index", label: "Index", sortable: false },
    { key: "createdAt", label: "Registration Date", sortable: false },
    { key: "fullname", label: "Member", sortable: false },
    { key: "status", label: "Status", sortable: false },
    { key: "membercode", label: "Member Number", sortable: false },
    { key: "platformdepartmentname", label: "Department", sortable: false },
  ];
  const perPage = ref(20);
  const totalUsers = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [20, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("fullname");
  const isSortDirDesc = ref(true);
  const roleFilter = ref(null);
  const planFilter = ref(null);
  const statusFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value
      ? refUserListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers,
    };
  });

  const refetchData = () => {
    refUserListTable.value.refresh();
  };

  watch(
    [currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter],
    () => {
      refetchData();
    }
  );

  const fetchInstructers = (ctx, callback) => {
    store
      .dispatch("apps-members/fetchAllMembers", {
        currentPage: currentPage.value,
        perPage: perPage.value,
        searchQuery: searchQuery.value,
      })
      .then((response) => {
        const { users, total } = response.data;
        //console.log("some resposne");
        callback(users);
        totalUsers.value =
          users.length >= 0 && users.length < 20 ? users.length : 200;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching users list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = (role) => {
    if (role === "subscriber") return "primary";
    if (role === "author") return "warning";
    if (role === "maintainer") return "success";
    if (role === "editor") return "info";
    if (role === "admin") return "danger";
    return "primary";
  };

  const resolveUserRoleIcon = (role) => {
    if (role === "subscriber") return "UserIcon";
    if (role === "author") return "SettingsIcon";
    if (role === "maintainer") return "DatabaseIcon";
    if (role === "editor") return "Edit2Icon";
    if (role === "admin") return "ServerIcon";
    return "UserIcon";
  };

  const resolveUserStatusVariant = (status) => {
    if (status == 0) return "warning";
    if (status == 1) return "success";
    if (status == 2) return "secondary";
    if (status == 500) return "danger";
    return "success";
  };
  const resolveUserStatusVariantText = (status) => {
    if (status == null) return "active";
    if (status == 0) return "deleted";
    if (status == 1) return "active";
    if (status == 2) return "disabled";
    if (status == 500) return "blocked";
    return "primary";
  };

  return {
    fetchInstructers,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    resolveUserStatusVariantText,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  };
}
